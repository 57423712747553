.modalContainer {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    z-index: 9999999999;
    background-color: rgba($color: #000000, $alpha: 0.2);

    & .modalCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background: white;
        border-radius: 12px;
        z-index: 999999;
    }
}

.active {
    display: flex;
}