@import "../../../../helpers/variableBreakpoints.scss";
@import "../../../../helpers/responsiveBreakpointsMixins.scss";

.schedule {
  width: 770px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
  background-color: white;
  margin-top: 40px;
  position: relative;
  & .responsiveArrowWrapper {
    display: none;
    @include medium {
      display: flex;
      position: absolute;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    & .responsiveArrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 100px;
      border-radius: 8px;
      background-color: #f3f3f3;

      &:hover {
        background-color: #e2e2e2;
      }
    }
  }
  & .left {
    left: 10px;
  }
  & .right {
    right: 10px;
  }
  & .topSide {
    display: flex;
    margin: 0 30px;
    width: calc(100% -60px);
    position: relative;
    justify-content: flex-end;
    padding-top: 30px;
    @include medium {
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      height: 100px;
    }
    & .title {
      position: absolute;
      font-size: 18px;
      font-family: SofiaBold;
      color: $darkBlue;
      left: 0;
      @include medium {
        position: relative;
      }
    }

    & .downloadSyllabusPdf {
      display: flex;
      align-items: center;
      width: 212px;
      background-color: #ffd3d3;
      border-radius: 8px;
      height: 30px;
      justify-content: space-around;
      cursor: pointer;
      align-items: center;
      margin-right: 10px;
      @include medium {
        margin-right: 0px;
      }

      & .formatXLS {
        display: flex;
        border-radius: 8px;
        width: 46px;
        justify-content: center;
        align-items: center;
        height: 24px;
        box-shadow: 0px 4px 4px rgba(255, 0, 0, 0.25);
        background-color: white;

        & .formatIcon {
          width: 9px;
          height: 9px;
        }

        & .formatName {
          color: #cb0000;
          font-size: 11px;
          margin-left: 5px;
          font-family: SofiaBlack;
        }
      }

      & .downloadTitle {
        font-family: SofiaSemiBold;
        color: #cb0000;
        font-size: 14px;
      }
    }

    & .downloadSyllabus {
      display: flex;
      align-items: center;
      width: 212px;
      background-color: #e9fff8;
      border-radius: 8px;
      height: 30px;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;

      & .formatXLS {
        display: flex;
        border-radius: 8px;
        width: 46px;
        justify-content: center;
        align-items: center;
        height: 24px;
        box-shadow: 0px 5px 27px -8px rgba(94, 191, 155, 1);
        background-color: white;

        & .formatIcon {
          width: 9px;
          height: 9px;
        }

        & .formatName {
          color: #5ebf9b;
          font-size: 11px;
          margin-left: 5px;
          font-family: SofiaBlack;
        }
      }

      & .downloadTitle {
        font-family: SofiaSemiBold;
        color: #5ebf9b;
        font-size: 14px;
      }
    }

    & .feedback {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 117px;
      height: 30px;
      background-color: rgba(225, 238, 253, 0.51);
      border-radius: 8px;
      margin-left: 10px;

      & .feedbackTitle {
        font-size: 14px;
        font-family: SofiaSemiBold;
        color: $darkBlue;
      }
    }
  }

  & .scheduleTitlesSection {
    display: flex;
    margin-top: 34px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    & .responsiveTitles {
      display: none;
      @include medium {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        & .scheduleTitlesRow {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 50%;

          & .scheduleTitles {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;

            & .scheduleTitlesIcon {
              width: 30px;
              height: 30px;
            }
          }

          // & .ogretmen {
          //   margin-right: 130px;
          // }

          // & .tarih {
          //   margin-right: 80px;
          // }

          // & .user {
          //   margin-left: 25px;
          // }

          // & .date {
          //   margin-left: 140px;
          // }

          & td {
            font-size: 14px;
            font-family: SofiaMedium;
            color: $darkBlue;
            margin-left: 10px;
            text-align: center;
          }
        }
      }
    }

    & .scheduleTitlesTable {
      width: 100%;
      @include medium {
        display: none;
      }

      & .scheduleTitlesRow {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        & .scheduleTitles {
          display: flex;
          justify-content: center;
          align-items: center;

          & .scheduleTitlesIcon {
            width: 30px;
            height: 30px;
          }
        }

        & .ogretmen {
          margin-right: 130px;
        }

        & .tarih {
          margin-right: 80px;
        }

        & .user {
          margin-left: 25px;
        }

        & .date {
          margin-left: 140px;
        }

        & td {
          width: inherit;
          font-size: 14px;
          font-family: SofiaMedium;
          color: $darkBlue;
          margin-left: 10px;
          text-align: center;
        }
      }
    }
  }

  & .scheduleSection {
    display: flex;
    width: 100%;
    margin-top: 10px;

    & table {
      width: 100%;
      border-collapse: collapse;
      border: none;

      & tr {
        cursor: context-menu;
        height: 60px;
        width: 100%;
        border: none;
        text-align: center;
        box-shadow: 0 1px 2px 0 rgba(224, 236, 255, 0.95);

        & .scheduleTeacher {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 60px;

          & td {
            margin-left: 15px;
          }

          & .avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-left: 30px;
            overflow: hidden;

            & img {
              width: 100%;
            }
          }
        }

        & td {
          color: $darkBlue;
          font-size: 14px;
          font-family: SofiaMedium;
        }
      }

      & tr:hover {
        background-color: #f9f7ff;
        box-shadow: 0 1px 2px 0 rgba(224, 236, 255, 0.95);
      }
    }
  }

  & .greenTip {
    position: absolute;
    left: 20px;
    width: 20px;
    height: 20px;
  }
}
