@import "../../helpers/variableBreakpoints.scss";

.paginationContainer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
  display: flex;
  width: 100%;
  margin: 15px 0;
  justify-content: center;
  align-items: center;
  & .paginationWrapper {
    display: flex;
    & .paginationCircle {
      display: flex;
      width: 35px;
      height: 35px;
      box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 5px;
    }
    & .paginationNumber {
      cursor: pointer;
    }
    & .disableArrow {
      & .arrow {
        color: dimgray;
      }
    }
    & .hoverArrows {
      transition: 0.3s;
      cursor: pointer;
      background-color: white;
    }
    & .selectedCircle {
      background-color: $darkPurple;
      color: white;
    }
    & .hoverArrows:hover {
      background-color: $darkPurple;
      & .arrow {
        color: white;
      }
    }
  }
}
