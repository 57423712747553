@import "../../../helpers//variableBreakpoints.scss";

.activityDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 4;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  & .backButton {
    position: absolute;
    left: 70px;
    top: 110px;
    & .backButtonIcon {
      color: $darkBlue;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
  & .topSide {
    display: flex;
    justify-content: space-between;
    width: 770px;
    margin-top: 100px;

    & .dropdown {
      position: relative;
      display: inline-block;
      z-index: 1;

      & .dropdownName {
        display: flex;
        align-items: center;
        color: $darkBlue;
        width: 200px;
        height: 30px;
        cursor: pointer;
        font-family: SofiaBold;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
        padding: 10px 20px;
        padding-right: 40px;
        position: relative;

        & .downIcon {
          position: absolute;
          right: 10px;
          color: black;
        }
      }

      & .dropdownContent {
        display: none;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: white;
        width: calc(100% - 18px);
        left: 13px;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
        overflow: hidden;
        font-size: 14px;
        color: $darkBlue;
        font-family: SofiaBold;
        z-index: 1;

        & .dropdownItems {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
          background-color: white;
          box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
          cursor: pointer;

          &:hover {
            background-color: rgb(243, 243, 243);
          }
        }
      }

      & .active {
        display: flex;
      }
    }

    & .tabs {
      display: flex;
      width: 400px;
      box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
      border-radius: 8px;
      height: 40px;

      & .tabsButton {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SofiaBold;
        width: 200px;
        box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
        border-radius: 8px;
        transition: 0.3s;
        cursor: pointer;
      }

      & .tabsButtonActive {
        background-color: $darkPurple;
        color: white;
      }
    }
  }

  & .activityInfoCard {
    display: flex;
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
  }
}
