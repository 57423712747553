@import "../../../../helpers/variableBreakpoints.scss";
@import "../../../../helpers/responsiveBreakpointsMixins.scss";
h3 {
  padding: 10px;
}
.announcementsCard {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding-bottom: 20px;
  max-height: 600px;
  width: 770px;
  background-color: #fefdff;
  position: relative;
  margin-top: 40px;
  transition: height 1s;
  box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);

  & .topSide {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;

    & .title {
      margin-left: 20px;
      margin-top: 27px;
      font-family: SofiaBold;
      color: $darkBlue;
      font-size: 18px;
    }

    & .addAnnouncements {
      position: absolute;
      display: flex;
      align-items: center;
      right: 20px;
      top: 27px;
      cursor: pointer;

      & .addAnnouncementsIcon {
        width: 20px;
        margin-right: 10px;
        color: $darkPurple;
      }

      & .addAnnouncementsTitle {
        color: $darkPurple;
      }
    }

    & .addAnnouncements:hover .addAnnouncementsTitle {
      text-decoration: underline;
    }
  }

  & .announcementsSection {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;

    & .homeworkTitles {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px 0;
      margin-left: 60px;

      & .homeworkTitleHomework {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $darkBlue;
        font-family: SofiaBold;
        width: 250px;
      }

      & .homeworkTitle {
        color: $darkBlue;
        font-family: SofiaBold;
      }
    }

    & .announcements {
      display: flex;
      position: relative;
      align-items: center;
      margin: 0 20px;
      cursor: pointer;
      height: 40px;
      width: calc(100% - 40px);
      border-radius: 12px;
      padding: 10px;
      &:hover {
        background-color: rgb(233, 233, 233);
      }

      & .imageCircle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        & img {
          width: 100%;
        }
      }

      & .announcementsTitles {
        display: flex;
        width: 70%;
        justify-content: space-around;

        & .announcementsTitle {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: SofiaMedium;
          color: $darkBlue;
          font-size: 14px;
        }

        & .width {
          width: 200px;
          text-align: center;
        }

        & .class {
          width: 100px;
        }
      }

      & .announcementsAdminIcon {
        display: flex;
        align-items: center;
        position: absolute;
        right: 100px;
        margin-right: 20px;
        justify-content: flex-end;
        width: 30%;

        & .editIcon {
          color: rgba(94, 191, 155, 1);
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-right: 10px;
        }

        & .trashIcon {
          color: red;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }

    & .announcementsDate {
      position: absolute;
      right: 10px;
      font-size: 12px;
      color: $lightPurple;
      right: 0;
    }
  }

  & .seeAll {
    display: flex;
    position: absolute;
    cursor: pointer;
    height: 36px;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: $lightPurple;
  }
}

.errorMessage {
  color: red;
}

.big {
  width: 1000px;
}

.activeSee {
  height: max-content;
  padding-bottom: 20px;
}

.deleteQuestion {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 10px 20px;

  & .deleteQuestionTitle {
    font-family: SofiaBold;
    font-size: 16px;
  }

  & .deleteQuestionButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 2;

  & .dropdownName {
    display: flex;
    align-items: center;
    color: $darkBlue;
    width: 356px;
    height: 30px;
    cursor: pointer;
    font-family: SofiaBold;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
    padding: 10px 20px;
    padding-right: 40px;
    position: relative;

    & .downIcon {
      position: absolute;
      right: 10px;
      color: black;
    }
  }

  & .dropdownContent {
    display: none;
    position: absolute;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: calc(100% - 18px);
    left: 13px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
    overflow: hidden;
    font-size: 14px;
    color: $darkBlue;
    font-family: SofiaBold;
    z-index: 1;

    & .dropdownItems {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: white;
      box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
      cursor: pointer;

      &:hover {
        background-color: rgb(243, 243, 243);
      }
    }
  }

  & .active {
    display: flex;
  }
}
.detailWrapper {
  width: 770px;
  @include medium {
    width: 90%;
  }
  & .announcementsDetailText {
    padding: 10px;
    word-wrap: break-word;
  }
}
