@import '../../helpers/variableBreakpoints.scss';
@import "../../helpers/responsiveBreakpointsMixins.scss";


.apps {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    & .appsContainer {
        display: flex;
        width: 1000px;
        height: 500px;
        margin-top: 40px;
        flex-direction: column;

        & .title {
            margin-top: 30px;
            margin-left: 35px;
            font-size: 18px;
            color: $darkBlue;
            font-family: SofiaBold;

            & span {
                color: $lightPurple
            }
        }

        & .appsGrid {
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(190px, 200px));
            @include medium{
                margin: auto;
            }

            & .renderApps {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 25px;
                margin: 20px;
                cursor: pointer;
                padding: 20px;

                & .appAvatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    box-shadow: 0 3px 8px 0 rgba(110, 42, 245, 0.11);
                    border-radius: 50%;
                    overflow: hidden;

                    & .icon {
                        width: 100%;
                    }

                    & .office {
                        width: 50%;
                    }

                    & .morpa {
                        width: 70%;
                        border-radius: 25px;
                    }

                    & .brain {
                        width: 65%;
                    }

                    & .actively {
                        width: 80%;
                    }

                }

                & .appName {
                    color: $darkBlue;
                    font-family: SofiaBold;
                    margin-top: 20px;
                    text-align: center;
                }
            }

            & .renderApps:hover {
                background-color: whitesmoke;
            }

        }
    }
}
