@import "../../helpers/variableBreakpoints.scss";

.homeworkContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    & .announcementsCard {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        height: 460px;
        cursor: pointer;
        width: 790px;
        background-color: #fefdff;
        align-items: center;
        position: relative;
        margin-top: 40px;
        box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);

        & .topSide {
            display: flex;
            width: 100%;
            position: relative;
            align-items: center;

            & .title {
                margin-left: 20px;
                margin-top: 27px;
                font-family: SofiaBold;
                color: $darkBlue;
                font-size: 18px;
            }

            & .addAnnouncements {
                position: absolute;
                display: flex;
                align-items: center;
                right: 20px;
                top: 27px;
                cursor: pointer;

                & .addAnnouncementsIcon {
                    width: 20px;
                    margin-right: 10px;
                    color: $darkPurple;
                }

                & .addAnnouncementsTitle {
                    color: $darkPurple;
                }
            }

            & .addAnnouncements:hover .addAnnouncementsTitle {
                text-decoration: underline;
            }
        }

        & .announcementsSection {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            width: 100%;

            & .homeworkTitles {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0;

                & .homeworkTitleHomework {
                    color: $darkBlue;
                    font-family: SofiaBold;
                    width: 180px;
                }

                & .homeworkTitle {
                    color: $darkBlue;
                    font-family: SofiaBold;
                }
            }

            & .announcements {
                display: flex;
                position: relative;
                align-items: center;
                margin: 0 20px;
                height: 60px;
                border-radius: 12px;
                padding: 0 10px;
                width: calc(100% - 70px);
                transition: 0.3s;

                &:hover {
                    background-color: rgb(241, 241, 241);
                }

                & .imageCircle {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;

                    & img {
                        width: 100%;
                    }
                }

                & .announcementsTitle {
                    font-family: SofiaMedium;
                    color: $darkBlue;
                    font-size: 14px;
                    margin-left: 20px;
                }

                & .announcementsTitleTeacher {
                    font-family: SofiaBold;
                    color: $darkBlue;
                    font-size: 14px;
                    margin-left: 20px;
                }

                & .announcementsTitleNotComplete {
                    font-family: SofiaBold;
                    color: red;
                    font-size: 14px;
                    margin-left: 20px;

                }

                & .announcementsTitleComplete {
                    font-family: SofiaBold;
                    color: $darkBlue;
                    font-size: 14px;
                    margin-left: 20px;
                }

                & .announcementsAdminIcon {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 100px;
                    margin-right: 20px;
                    justify-content: flex-end;
                    width: 30%;

                    & .editIcon {
                        color: rgba(94, 191, 155, 1);
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        margin-right: 10px;
                    }

                    & .trashIcon {
                        color: red;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
            }

            & .announcementsDate {
                position: absolute;
                right: 20px;
                font-size: 12px;
                color: $lightPurple;
                right: 0;
            }
        }

        & .seeAll {
            display: flex;
            position: absolute;
            height: 36px;
            bottom: 0;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: $lightPurple;
        }
    }

    & .activeSee {
        height: max-content;
        padding-bottom: 40px;
    }
}

.errorMessage {
    color: red;
}

.linkSolid {
    margin-left: 20px;
    color: $darkBlue;
    cursor: pointer;
}