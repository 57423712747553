.messageContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 5px 0;
	padding: 5px 0;
	height: fit-content;

	&:hover {
		cursor: pointer;
	}

	&__avatar {
		width: 60px;
		height: 60px;
		margin-left: 20px;
		img {
			width: 100%;
			border-radius: 50%;
		}
		&__unread {
			position: relative;
			right: -65%;
			bottom: 25px;
			width: 24px;
			height: 24px;
			background-color: #6e2af5;
			border: 3px solid white;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			color: white;
			font-weight: bold;
		}
	}
	&__content {
		width: 70%;
		&__name {
			color: #061b52;
			font-weight: 700;
			font-size: 16px;
		}
		&__message {
			color: #061b52;
			font-weight: light;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-ms-text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
			font-size: 12px;
			&.bold {
				font-weight: bold;
				color: #061b52;
			}
		}
	}

	&__time {
		color: #b0b7c8;
		align-self: flex-start;
		margin-right: 20px;
	}
}
