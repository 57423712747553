@import "../../../../helpers/variableBreakpoints.scss";
@import "../../../../helpers/responsiveBreakpointsMixins.scss";
.userDetailContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1000px;
  flex-direction: column;
  height: 100%;
  position: relative;
  & .modalIcon {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
  & .errorMessage {
    color: red;
  }
  & .iconLock {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
  & .checkSolid {
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    color: rgb(35, 124, 35);
    z-index: 1;
  }
  & .timesSolid {
    position: absolute;
    right: 15px;
    width: 20px;
    color: red;
    height: 20px;
    z-index: 1;
  }
  & .feedback {
    display: flex;
    margin: 20px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    height: 30px;
    background-color: rgba(225, 238, 253, 0.51);
    border-radius: 8px;
    margin-right: -10px;

    & .feedbackIcon {
      color: $darkBlue;
    }

    & .feedbackTitle {
      font-size: 14px;
      font-family: SofiaSemiBold;
      color: $darkBlue;
      margin-left: 10px;
    }
  }
  & .backButton {
    position: absolute;
    left: 50px;
    top: -30px;
    z-index: 9999;
    & .backButtonIcon {
      color: $darkBlue;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
  & .detailSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    & .formSection {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .inputRow {
        display: flex;
        & .inputGroup {
          display: flex;
          margin: 15px 15px;
          flex-direction: column;
        }
      }
    }
    & .classesCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      border-radius: 12px;
      margin-left: 30px;
      box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
      position: relative;
      background-color: white;
      margin-bottom: 60px;

      & .classBackground {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 354px;
        min-height: 110px;
        height: 120px;
        border-radius: 12px;
        overflow: hidden;

        & img {
          height: 100%;
        }
      }

      & .teacherAvatarBackground {
        display: flex;
        position: absolute;
        top: 100px;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 95px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 4px 10px 0 #eee5fd;

        & .teacherAvatar {
          width: 85px;
          height: 85px;
          position: relative;

          & img {
            object-fit: cover;
            width: 85px;
            height: 85px;
            border-radius: 50%;
          }

          & .editIcon {
            position: absolute;
            top: 35%;
            width: 45px;
            cursor: pointer;
            height: 45px;
            right: -22.5px;
          }
        }
      }

      & .name {
        font-family: SofiaBold;
        color: $darkBlue;
        font-size: 16px;
        margin-top: 85px;
      }

      & .classroomName {
        color: $lightPurple;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: -20px;
      }

      & .alertboxes {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
.changePasswordContainer {
  margin-left: 50px;
  margin-top: 40px;
  & .icon {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
  & .checkSolid {
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    color: rgb(35, 124, 35);
    z-index: 1;
  }
  & .timesSolid {
    position: absolute;
    right: 15px;
    width: 20px;
    color: red;
    height: 20px;
    z-index: 1;
  }
}
.renderAppRow {
  display: flex;
  width: 100%;
  align-items: center;
  height: 80px;
  position: relative;
  justify-content: space-around;
  border-radius: 12px;
  transition: 0.3s;

  & .appAvatarWrapper {
    display: flex;
    max-width: 200px;
    width: 150px;
    padding-left: 50px;
    align-items: center;

    & .appAvatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 50%;
      box-shadow: 0 3px 8px 0 rgba(110, 42, 245, 0.11);
      border-radius: 50%;
      overflow: hidden;

      & .icon {
        width: 100%;
      }

      & .office {
        width: 50%;
      }

      & .morpa {
        width: 70%;
        border-radius: 25px;
      }

      & .brain {
        width: 65%;
      }

      & .actively {
        width: 80%;
      }
    }
    .appName {
      margin-left: 15px;
      font-family: SofiaMedium;
    }
  }
  & .appUsername {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .appPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
  }
  & .editIcon {
    width: 70px;
    color: rgb(35, 126, 42);
    cursor: pointer;
  }
}
.renderAppRow:hover {
  background-color: rgb(235, 235, 235);
}
.appsPasswords {
  width: 1000px;
  @include medium {
    margin-top: 100px;
  }
  & .titles {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    font-family: SofiaBold;
    color: $darkBlue;
    margin-top: 30px !important;
    margin-left: 10px;
    & .appNameTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
    }
    & .usernameTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
    }
    & .passwordTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
    }
    & .edit {
      width: 100px;
    }
  }
}
.renderApps {
  overflow-y: scroll;
  margin-top: 10px;
  height: 350px;
}
