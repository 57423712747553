@import "../../helpers/variableBreakpoints.scss";

.sidebarContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
  background-color: $darkPurple;

  & .logo{
    display: flex;
    width: 100%;
    padding: 16px 0;
    justify-content:  center;
    & .adminLogo{
      width: 100px;
      height: 100px;
    }
  }
 & .sidebarIcon{
   width: 36px;
   margin-top: 5px;
   height: 36px;
 }

  & .sidebarCard {
    position: fixed;
    left: 30px;
    display: flex;
    flex-direction: column;

    & .sidebarButtons {
      & .sidebarButton {
        display: flex;
        align-items: center;
        justify-content:center;
        border-radius: 18px;
        color: white;
        height: 30px;
        width: max-content;
        cursor: pointer;
        padding: 8px 12px;
        margin: 16px 0;
        transition: 0.3s;

        &:hover {
          color: black;
          background-color: white;
        }

        display: flex;

        & .buttonTitle {
          margin-left: 12px;
          font-family: SofiaMedium;
        }
      }

      & .buttonActive {
        color: black;
        background-color: white;
      }
    }
  }
}