@import "../../../../helpers/variableBreakpoints.scss";

.apps {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & .appsContainer {
    display: flex;
    width: 1000px;
    margin-top: 40px;
    flex-direction: column;

    & .title {
      margin-top: 30px;
      margin-left: 35px;
      font-size: 18px;
      color: $darkBlue;
      font-family: SofiaBold;

      & span {
        color: $lightPurple;
      }
    }

    & .appsGrid {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(190px, 200px));

      & .renderApps {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 25px;
        margin: 20px;
        cursor: pointer;
        padding: 20px;

        & .checkSolid {
          position: absolute;
          display: flex;
          width: 20px;
          justify-content: center;
          top: 10px;
          right: 10px;
          align-items: center;
          height: 20px;
          border-radius: 50%;
          background-color: $darkPurple;

          & .checkSolidIcon {
            color: white;
            width: 10px;
            height: 10px;
          }
        }

        & .appAvatar {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: 0 3px 8px 0 rgba(110, 42, 245, 0.11);
          border-radius: 50%;
          overflow: hidden;

          & .icon {
            width: 100%;
          }

          & .office {
            width: 50%;
          }

          & .morpa {
            width: 70%;
            border-radius: 25px;
          }

          & .brain {
            width: 65%;
          }

          & .actively {
            width: 80%;
          }
        }

        & .appName {
          color: $darkBlue;
          font-family: SofiaBold;
          margin-top: 20px;
          text-align: center;
        }
      }

      & .selected {
        border: solid $darkPurple 3px;
      }

      & .renderApps:hover {
        background-color: whitesmoke;
      }
    }
  }
}
