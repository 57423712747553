@import "../../helpers/responsiveBreakpointsMixins.scss";
.homeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f6f2ff;
  padding-bottom: 36px;

  & .homeContainer {
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    @include x-large {
      flex-direction: column;
    }

    & .homeMain {
      flex: 2;
    }

    & .rightSide {
      flex: 1;
    }
  }
}
