@import "../../helpers/variableBreakpoints.scss";
@import "../../helpers/responsiveBreakpointsMixins.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary {
  border-radius: 8px;
  background-color: $darkPurple;
  color: white;
  width: 400px;
  min-height: 44px;
  margin: 24px 0;
  box-shadow: 0 2px 4px 0 rgba(53, 10, 139, 0.49);
  cursor: pointer;
  transition: 0.3s;
  @include medium {
    width: 300px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.modal {
  border-radius: 8px;
  background-color: $darkPurple;
  color: white;
  width: max-content;
  height: 44px;
  margin: 24px 0;
  padding: 0 10px;
  box-shadow: 0 2px 4px 0 rgba(53, 10, 139, 0.49);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.9;
  }
}
.change {
  border-radius: 8px;
  background-color: $darkPurple;
  color: white;
  height: 44px;
  width: 280px;
  margin: 24px 0;
  padding: 0 10px;
  box-shadow: 0 2px 4px 0 rgba(53, 10, 139, 0.49);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.9;
  }
}
.outlined {
  border-radius: 8px;
  background-color: white;
  border-color: $darkPurple;
  color: $darkPurple;
  width: max-content;
  height: 30px;
  border: solid 1px;
  margin: 24px 0;
  padding: 0 30px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.9;
  }
}

.delete {
  border-radius: 8px;
  background-color: $darkPurple;
  color: white;
  width: max-content;
  height: 32px;
  margin: 24px 0;
  padding: 0 10px;
  box-shadow: 0 2px 4px 0 rgba(53, 10, 139, 0.49);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.9;
  }
}
