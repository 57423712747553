@import '../../helpers/variableBreakpoints.scss';

.selectbox {
    display: flex;
    justify-content: center;
    align-items: center;

    .dropdown {
        position: relative;
        display: inline-block;
        z-index: 1;

        & .dropdownName {
            display: flex;
            align-items: center;
            color: $darkBlue;
            width: 350px;
            height: 30px;
            font-family: SofiaBold;
            border-radius: 8px;
            box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
            padding: 10px 20px;
            padding-right: 40px;
            position: relative;

            & .downIcon {
                position: absolute;
                right: 10px;
                cursor: pointer;
                color: black;
            }

            & .multiselect {
                display: flex;
                align-items: center;
                position: relative;
                padding: 0 10px;
                padding-right: 30px;
                box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
                border-radius: 8px;
                margin: 0 3px;
                height: 30px;
                z-index: 1000;

                & .cross {
                    right: 5px;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    cursor: pointer;
                }

                & .multiselectName {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: SofiaBold;
                }
            }
        }

        & .dropdownContent {
            display: none;
            position: absolute;
            flex-direction: column;
            overflow-y: auto;
            align-items: center;
            background-color: white;
            height: 340px;
            width: calc(100% - 18px);
            left: 13px;
            border-radius: 8px;
            height: 250px;
            box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
            font-size: 14px;
            color: $darkBlue;
            font-family: SofiaBold;
            z-index: 1;

            & .dropdownItems {
                overflow-y: visible;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 50px;
                width: 100%;
                background-color: white;
                box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
                cursor: pointer;

                &:hover {
                    background-color: rgb(243, 243, 243);
                }
            }
        }

        & .active {
            display: flex;
        }
    }

}

.active {
    display: flex;
}