@import "../../../../helpers/variableBreakpoints.scss";
@import "../../../../helpers/responsiveBreakpointsMixins.scss";
.teachers {
  width: 370px;
  margin-top: 40px;
  margin-left: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
  background-color: white;
  position: relative;
  @include medium {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 770px;
    background-color: #fefdff;
    position: relative;
    margin-top: 40px;
    margin-left: 0px;
    box-shadow: 0 2px 6px 0 rgba(110, 42, 245, 0.09);
  }

  & .title {
    margin-left: 20px;
    margin-top: 30px;
    font-family: SofiaBold;
    font-size: 18px;
    color: $darkBlue;
  }

  & .teachersSection {
    display: flex;
    align-items: center;
    margin-top: 12px;
    height: 325px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;

    & .teachersLabel {
      display: flex;
      align-items: center;
      height: 50px;
      width: 330px;
      margin: 10px 0;

      & .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        & img {
          width: 100%;
        }
      }

      & .teacherInfo {
        display: flex;
        margin-left: 20px;
        flex-direction: column;
        height: 50px;
        align-items: flex-start;
        margin-top: 10px;

        & .name {
          font-family: SofiaBold;
          font-size: 16px;
          color: $darkBlue;
        }

        & .branch {
          font-size: 14px;
          color: $lightPurple;
        }
      }

      & .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 20px;
        border-radius: 8px;
        width: 32px;
        height: 30px;
        box-shadow: 0 2px 5px 0 rgba(110, 42, 245, 0.15);
        background-color: white;

        & .moreIcon {
          width: 100px;
          height: 13px;
          color: $darkBlue;
        }
      }
    }
  }
}
.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  img {
    width: 100%;
    border-radius: 50%;
  }
}
.MessageCheckIcon {
  position: absolute;
  height: 40px;
  top: 7px;
  width: 40px;
  left: 10px;
  @include medium {
    top: 15px;
  }
}
