@import "../../../helpers/variableBreakpoints.scss";
@import "../../../helpers/responsiveBreakpointsMixins.scss";
.subBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 0px;
  transition: height 0.3s;
  background-color: $darkPurple;
  z-index: -9;
  width: calc(100% - 10vw);
  padding-left: 10vw;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 1);

  @include medium {
    flex-direction: column;
    justify-content: space-around;
    padding-left: 0px;
    width: 100%;
  }
  & .subBarButtons {
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    height: 46px;
    border-radius: 12px;
    padding-right: 12px;
    transition: 0.3s;
    margin: 0 15px;
    @include medium {
      width: 200px;
    }
    & .subBarButtonTitle {
      color: white;
      font-family: SofiaSemiBold;
      margin-left: 60px;
    }

    & .subBarButtonsIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
    }

    & .kampusIcon {
      position: absolute;
      left: 10px;
      margin-top: 4px;
      width: 38px;
      height: 38px;
    }

    & .appsIcon {
      margin-top: 4px;
      position: absolute;
      width: 50px;
      left: 5px;
      height: 50px;
    }

    & .docsIcon {
      position: absolute;
      left: 5px;
      margin-top: 4px;

      width: 50px;
      height: 50px;
    }
  }

  & .subBarButtons:hover .subBarButtonTitle {
    color: $darkBlue;
  }

  & .backgroundWhite {
    background-color: white;
    color: $darkBlue;

    & .subBarButtonTitle {
      color: $darkBlue;
    }
  }

  & .subBarButtons:hover {
    background-color: white;
  }

  & .displayNone {
    display: none;
  }
}

.active {
  height: 80px;
  @include medium {
    height: 300px;
  }
}
