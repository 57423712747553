.container {
    display: flex;
}

.Aside {
    display: flex;

    & .fileName {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}