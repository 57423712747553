body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: SofiaRegular;
  src: url(./assets/fonts/SofiaProRegular.woff);
}

@font-face {
  font-family: SofiaMedium;
  src: url(./assets/fonts/SofiaPro-Medium.woff);
}

@font-face {
  font-family: SofiaBold;
  src: url(./assets/fonts/SofiaPro-Bold.woff);
}

@font-face {
  font-family: SofiaSemiBold;
  src: url(./assets/fonts/SofiaPro-SemiBold.woff);
}

@font-face {
  font-family: SofiaBlack;
  src: url(./assets/fonts/SofiaPro-Black.woff);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  padding: 0;
  margin: 0
}

html, body, #root, .App {
  width: 100%;
  height: 100%
}